import Flickity from 'flickity';
import 'flickity/css/flickity.css';

export const ServiceDetailsDialog = {
  init() {
    this.bindListeners();
  },

  bindListeners() {
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const dialog = e.target.closest('drb-dialog#service-details-dialog');
      if (!dialog) return;

      this.setupFlickity(dialog);
      this.bindThumbnails(dialog);
      this.bindDescription(dialog);
    });
  },

  setupFlickity(dialog) {
    const carousel = dialog.querySelector('[data-service-gallery-carousel]');
    if (!carousel) return;

    const hasMultipleImages = dialog.querySelectorAll('[data-service-gallery-gallery-thumbnail]').length > 1;

    this.flkty = new Flickity(carousel, {
      wrapAround: true,
      contain: true,
      prevNextButtons: hasMultipleImages,
      pageDots: false,
      draggable: hasMultipleImages,
    });

    this.flkty.on('change', (index) => {
      // sync thumbnail buttons on change (dragging carousel or clicking thumbnails)
      dialog.querySelectorAll('[data-service-gallery-button]').forEach((button, buttonIndex) => {
        button.toggleAttribute('data-current', index === buttonIndex);
      });
    });
  },

  bindThumbnails(dialog) {
    dialog.querySelectorAll('[data-service-gallery-button]').forEach((button, buttonIndex) => {
      button.addEventListener('click', () => {
        this.flkty.selectCell(buttonIndex);
      });
    });
  },

  bindDescription(dialog) {
    const descriptionText = dialog.querySelector('[data-service-description]');
    if (descriptionText.scrollHeight > 240) {
      descriptionText.classList.add('service-details__description--scrolling');
    }
  },
};
