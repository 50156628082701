import { PristineValidation } from '~/globals/form-validation/form-validation';
import { getCSRFToken } from '~/shared/utils/request';
import { DrbConfirm } from '~/web-components/drb-confirm/drb-confirm-helpers';
// eslint-disable-next-line import/no-unresolved
import checkIcon from '~/assets/icons/check-verified.svg?raw';

export const ServiceRequestDialog = {
  init() {
    this.bindListeners();
  },

  bindListeners() {
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const dialog = e.target;
      const form = dialog.querySelector('[data-service-request-form]');
      if (!form) return;

      this.bindFormValidation(form);
    });
  },

  bindFormValidation(form) {
    form.pristineValidation = PristineValidation(form);

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submitForm(form);
    });
  },

  async submitForm(form) {
    if (form.hasAttribute('is-submitting') || !form.pristineValidation?.validate()) return;

    const defaultServerErrorMsg = 'Failed to send message';
    const submitBtn = form.querySelector('[data-service-request-submit]');
    const formError = form.querySelector('[data-form-error]');

    // disable the submit button and show loading state
    const toggleFormSubmitting = (isSubmitting) => {
      form.toggleAttribute('is-submitting', isSubmitting);
      submitBtn.disabled = isSubmitting;
      submitBtn.classList.toggle('btn2--loading', isSubmitting);
    };

    // incase of server error, show error message
    const setServerErrorMsg = (errorMsg) => {
      if (!formError) return;
      formError.toggleAttribute('hidden', !errorMsg);
      formError.innerText = errorMsg;
    };

    // reset state
    toggleFormSubmitting(true);
    setServerErrorMsg(null);

    try {
      const response = await fetch(form.getAttribute('action'), {
        method: 'POST',
        body: new FormData(form),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': getCSRFToken(),
        },
      });

      if (!response.ok) {
        // use error message provided by BE if available (422), otherwise use default
        const responseText = await response.text();
        const errorMsg = response.status === 422 ? responseText : defaultServerErrorMsg;
        throw new Error(errorMsg || defaultServerErrorMsg);
      }

      const responseJson = await response.json();
      form.closest('drb-dialog').close();

      DrbConfirm.open({
        title: 'Your request was sent!',
        message: '',
        iconHtml: checkIcon,
        centered: true,
        cancelText: '',
        confirmText: 'View Request',
        confirmHref: responseJson.redirect_url,
        confirmClass: 'btn2--primary btn2--full-width',
      });
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : defaultServerErrorMsg;
      setServerErrorMsg(errorMsg);
      console.error(errorMsg, error);

      toggleFormSubmitting(false);
    }
  },
};
